import React, { useState } from 'react'
import { request } from '../../api/fetchAPI'
import { useSelector } from 'react-redux'



export default function AddClient() {
  const [state, setState] = useState()
  const [photo, setPhoto] = useState()
  const { token } = useSelector((state) => state.auth)

  const handleStata = (e) => {
    setState((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }


  const handleRegiter = async (e) => {
    try {


      e.preventDefault()
      let filename = null
      let formData = new FormData()
      filename = crypto.randomUUID() + photo.name
      formData.append("filename", filename)
      formData.append("image", photo)
      await fetch("https://api.bioscientifictech.com/upload/image", {
        Headers: {
          Authorization: `Bearer${token}`,

        }, method: "POST",
        body: formData
      }
      )



       request("/webPage/createClient", "POST", {
        Authorization: `Bearer${token}`,
        "Content-Type": "application/json"
      }, { ...state, clientImg: filename, })

    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="min-h-screen p-6 bg-gray-100 flex items-center justify-center">
      <div className="container max-w-screen-lg mx-auto">
        <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
          <div className=" lg:grid-cols-3">
            <div className="text-gray-600">
              <form onSubmit={handleRegiter}>
                <div className="space-y-12">
                  <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Client Entry Form</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                      This information will be displayed publicly so be careful what you share.
                    </p>

                    <div className="mt-10 row  gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="mb-3">
                        <label for="formFile" ClassName="form-label">Upload Picture</label>
                        <input className="form-control w-72" name="photo" type="file" 
                        id="photo" onChange={(e) => setPhoto(e.target.files[0])} />


                      </div>
                    </div>
                    
                  </div>

                  <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Client Information</h2>


                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-3">


                        <div ClassName="form-floating mb-3">
                          <input type="text" className="form-control" id="floatingInput 
                          inputState" name="clientName"
                            onChange={handleStata} placeholder="e.g Kits" />
                          <label for="floatingInput">Client Name</label>
                        </div>

                      </div>


                      <div className="col-span-full">

                        <div className="mt-2">


                          <div ClassName="form-floating">
                            <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{ height: "100px" }}

                              name="Description"
                              onChange={handleStata}
                            ></textarea>
                            <label for="floatingTextarea2"
                            >Product About</label>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <button className='btn btn-primary mt-2 mb-2'>
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}