/* eslint-disable */
import React from 'react'
import Haroon from "../../asset/haron.PNG"
import Arslan from "../../asset/Arslan.jpeg"
const links = [
  { name: 'Finding Problem' },
  { name: 'Research' },
  { name: 'Finding Ways' },
  { name: 'Suitable Product' },
]
const stats = [
  { name: 'Offices Rawalpindi', value: '1' },
  { name: 'Developed Trust', value: '300%' },
  { name: 'Working Hours per week', value: '40' },
  { name: 'Products Variaty Available', value: '100+' },
]


export default function About() {
  return (
    <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">

      <img
        src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
      />
      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Who We Are</h2>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Bio Scientific Technology is a leading innovator in the realm of biotechnology,
            dedicated to pushing the boundaries of scientific discovery and technological
            advancement. Established with a vision to revolutionize the way we understand,
            interact with, and utilize biological systems, Bio Scientific Technology
            has emerged as a frontrunner in the industry, offering cutting-edge solutions to address
            some of the most pressing challenges in healthcare, agriculture, environmental sustainability, and beyond.
          </p>
        </div>
        <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10">
            {links.map((link) => (
              <a key={link.name} href={link.href}>
                {link.name} <span aria-hidden="true">&rarr;</span>
              </a>
            ))}
          </div>
          <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat) => (
              <div key={stat.name} className="flex flex-col-reverse">
                <dt className="text-base leading-7 text-gray-300">{stat.name}</dt>
                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">{stat.value}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
          <div className="max-w-2xl">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Meet our leadership</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">

              As stewards of our organization, our leadership embodies a vision that inspires, guides, and uplifts.
              Rooted in integrity,
              they navigate challenges with wisdom and resilience, fostering an environment of trust and collaboration.
            </p>
          </div>

          <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">

            <li >
              <div className="flex items-center gap-x-6">
                <img className="h-16 w-16 rounded-full" src={Haroon} alt="" />
                <div>
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">Haroon Yaqoob</h3>
                  <p className="text-sm font-semibold leading-6 text-indigo-600">Founder/CEO</p>
                </div>
              </div>
            </li>

            <li>
              <div className="flex items-center gap-x-6">
                <img className="h-16 w-16 rounded-full" src={Arslan} alt="" />
                <div>
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">Rana Muhammad Arslan</h3>
                  <p className="text-sm font-semibold leading-6 text-indigo-600">Software Solution Engineer </p>
                </div>
              </div>
            </li>

          </ul>
        </div>
      </div>
    </div>
  )
}
