import { Fragment, useState, React, useEffect } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import { Menu } from '@headlessui/react'
import { Button } from 'antd';
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'


import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import { request } from '../../api/fetchAPI';
import logo from "../../asset/BioLogo.PNG"
import { logout } from '../../redux/authSlice'
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux'
import person from "../../asset/profile.jpg"
import "./AppHeader.css"


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const [products, setProductsLine] = useState([])

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [loadings, setLoadings] = useState([]);
  const { user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()


  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };


  const handleLogout = () => {
    dispatch(logout())
    navigate("/Login")
  }


 const fetchProducts = async () => {
    try {
      const data = await request("/product/getAll/productLine", "GET")
      setProductsLine(data)

    } catch (error) {
      console.log(error)
    }
  } 



  const callsToAction = [
    { name: 'Want Quotation', href: '#', icon: PlayCircleIcon },
    { name: 'Contact Us', href: '#', icon: PhoneIcon },
  ]

   useEffect(() => {
    fetchProducts()

  }, []) 





  return (
    <header className="bg-white">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Bio Scientific Technology</span>

            <img
              className="h-8 w-auto"
              src={logo}

              alt=""
            />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <Link to="/" className="text-sm font-semibold leading-6 text-gray-900 productMenue">
            Home
          </Link>
          <div>
            <Popover className="relative">
              <Popover.Button className="flex items-center gap-x-1 z-10 text-sm font-semibold leading-6 text-gray-900"
              >
                Product
                <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute -left-8 top-full z-10  ">
                <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div className="p-4">
              {products.map((products) => (
              <div key={products._id} className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
             
              <div>
                <span className="font-semibold text-gray-900" onClick={()=>navigate(`/Products/${products._id}`)}>
                  {products.productLine}
                  <span className="absolute inset-0" />
                </span>
                <p className="text-gray-600">{products.productLineType}</p>
              </div>
            </div>
              ))}
            </div>
            <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
              {callsToAction.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                >
                  <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                  {item.name}
                </a>
              ))}
            </div>
          </div>



                


                </Popover.Panel>
              </Transition>
            </Popover>
          </div>
          <Link to="/OurClient" className="text-sm font-semibold leading-6 text-gray-900 productMenue">Client</Link>
          <Link to="/OurPartner" className="text-sm font-semibold leading-6 text-gray-900 productMenue">Partners</Link>
          <Link to="/About" className="text-sm font-semibold leading-6 text-gray-900 productMenue">About Us</Link>
          <Link to="/ContactUs" className="text-sm font-semibold leading-6 text-gray-900 productMenue">Contact Us</Link>
          {!user
            ?
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <Button
                  type="primary"
                className='ml-2 bg-blue-500'

                loading={loadings[1]}
                onClick={() => { enterLoading(1); navigate("/Login") }}

              >
                Login
              </Button>

              <Button

                type="primary"
                className='ml-2 bg-blue-500'

                loading={loadings[2]}
                 onClick={() => { enterLoading(2); navigate("/SignUp") }}
              >
                Sign-In
              </Button>

            </div>
            :
            <Menu as="div" className="relative ml-20">
              <div>
                <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="h-8 w-8 rounded-full"
                   
                    src={user.img ? `https://api.bioscientifictech.com/images/${user.img}` : person}
                    alt=""
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 cursor-pointer origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (

                      <div className="flex justify-center align-middle mb-2">
                        <span

                        >
                          My Account

                        </span>
                      </div>
                    )}
                  </Menu.Item>

                  <Menu.Item>
                    {({ active }) => (


                      <div className="flex justify-center align-middle mb-2">
                        <Button
                          onClick={() => handleLogout()}
                          type='primary'
                          danger
                          className='bg-blue-700'
                        >
                          Sign out

                        </Button>
                      </div>



                    )}
                  </Menu.Item>

                  <Menu.Item>

                    {({ active }) => (

                      <div className="flex justify-center align-middle">
                        {user.role === "admin" &&
                          <Button
                            type='primary'
                            primary
                            className='bg-blue-700'
                            onClick={() => { navigate("/Panel") }}
                          >
                            ADMIN PANEL

                          </Button>}
                      </div>



                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          }

        </Popover.Group>

      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Bio Scientific Technology</span>

              <img
                className="h-8 w-auto"
                src={logo}

                alt=""
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Product
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>

                      <Disclosure.Panel className="space-y-2">
                      {products.map((products) => (
              <div key={products._id} className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50" onClick={()=>navigate(`/Products/${products._id}`)}>
                <span className="font-semibold text-gray-900" >
                  {products.productLine}
                  <span className="absolute inset-0" />
                </span>
                <p className="text-gray-600">{products.productLineType}</p>
          
            </div>
              ))}
                      {callsToAction.map((item) => (
              <div key={item.name} className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50" >
                <span className="font-semibold text-gray-900" >
                  {item.name}
                  <span className="absolute inset-0" />
                </span>
          
          
            </div>
              ))}
                      
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Link
                  to="/"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 hover:no-underline"
                >
                  Home
                </Link>
                <Link
                  to="/OurClient"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Client
                </Link>
                <Link
                  to="/OurPartner"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Partners
                </Link>
                <Link
                  to="/About"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  About Us
                </Link>
                <Link
                  to="/ContactUs"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Contact Us
                </Link>


              </div>

              <div className="py-6">
                <div>
                  {user
                  
                    ?

                    <Menu as="div" className="relative ml-20">
                    <div>
                      <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src={user?.img ? `https://api.bioscientifictech.com/images/${user.img}` : person}
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 cursor-pointer origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (

                            <div className="flex justify-center align-middle mb-2">
                              <span

                              >
                                My Account

                              </span>
                            </div>
                          )}
                        </Menu.Item>

                        <Menu.Item>
                          {({ active }) => (


                            <div className="flex justify-center align-middle mb-2">
                              <Button
                                onClick={() => handleLogout()}
                                type='primary'
                                danger
                                className='bg-blue-700'
                              >
                                Sign out

                              </Button>
                            </div>



                          )}
                        </Menu.Item>

                        <Menu.Item>

                          {({ active }) => (

                            <div className="flex justify-center align-middle">
                              {user?.role === "admin" &&
                                <Button
                                  type='primary'
                                  primary
                                  className='bg-blue-700'
                                  onClick={() => { navigate("/Panel") }}
                                >
                                  ADMIN PANEL

                                </Button>}
                            </div>



                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>

                    :
                    <div className=" lg:flex lg:flex-1 lg:justify-end">
                      <Button
                        className=" border-indigo-500 text-indigo-500"
                        type="primary"
                        loading={loadings[1]}
                        onClick={() => { enterLoading(1); navigate("/Login") }}

                      >
                        Login
                      </Button>

                      <Button

                        type="primary"
                        className='ml-2 bg-blue-500'

                        loading={loadings[2]}
                        o onClick={() => { enterLoading(2); navigate("/SignUp") }}
                      >
                        Sign-In
                      </Button>

                    </div>
                    
                  
                  }
                </div>

              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
