import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import ProductEntry from "../AddProduct/AddProduct"
import AddProductLine from '../AddProductline/AddProductLine';
import AddClient from '../AddClient/AddClient';
import AddPartner from '../AddPartner/AddPartner';
import ProductList from '../ProductList.jsx/ProductList';
import ProductLineList from '../ProductLineList/ProductLineList';


export default function SideMenue() {
 
  const[keys, setKeys] = useState(2)

  return (
    <div className="row w-100 mt-20 ">
      <div className="col-3 ">

        
            {/* Product management here */}

        <div
          className="nav flex-column nav-tabs text-center ml-2"
          style={{
            border:"1px solid black"
          }}

          role="tablist"
          aria-orientation="vertical"
        >
          <h4 className='p-2 bg-indigo-700 text-white'>Product Management</h4>
          <Nav.Link

            data-mdb-tab-init
            className=" tabsHover"
            onClick={()=>setKeys(1)}
          >
            Enter Product 
          </Nav.Link>
          <Nav.Link

            data-mdb-tab-init
            className="tabsHover"
            onClick={()=>setKeys(2)}
          >
            Product List
          </Nav.Link>

          <Nav.Link
            data-mdb-tab-init
            className="tabsHover"
            onClick={()=>setKeys(3)}
          >
            Our Product Line
          </Nav.Link>

          <Nav.Link
            data-mdb-tab-init
            className="tabsHover"
            onClick={()=>setKeys(4)}
          >
            Product Line List
          </Nav.Link>
          <Nav.Link
            data-mdb-tab-init
            className="tabsHover"
            role="tab"

            onClick={()=>setKeys(5)}
          >
            Out Of Stock Product
          </Nav.Link>
          <Nav.Link
            data-mdb-tab-init
            className="tabsHover"
            role="tab"

            onClick={()=>setKeys(6)}
          >
            Expire Products
          </Nav.Link>
          <Nav.Link
            data-mdb-tab-init
            className="tabsHover"
            role="tab"

            onClick={()=>setKeys(7)}
          >
            Group & Type 

          </Nav.Link>
         
        </div>

        
            {/* User Management */}

        <div
          className="nav flex-column nav-tabs text-center ml-2 mt-3"
          style={{
            border:"1px solid black"
          }}

          role="tablist"
          aria-orientation="vertical"
        >
          <h4 className='p-2 bg-lime-700 text-white'>user Management</h4>
          <Nav.Link

            data-mdb-tab-init
            className="tabsHover"
            onClick={()=>setKeys(8)}
          >
            User List
          </Nav.Link>
          <Nav.Link

            data-mdb-tab-init
            className="tabsHover"
            onClick={()=>setKeys(9)}
          >
            Product Review
          </Nav.Link>

          <Nav.Link
            data-mdb-tab-init
            className="tabsHover"
            onClick={()=>setKeys(10)}
          >
            Contact Us info
          </Nav.Link>

          <Nav.Link
            data-mdb-tab-init
            className="tabsHover"
            onClick={()=>setKeys(11)}
          >
            RFQ
          </Nav.Link>
          <Nav.Link
            data-mdb-tab-init
            className="tabsHover"
            role="tab"

            onClick={()=>setKeys(12)}
          >
            News Letter
          </Nav.Link>
      
         
        </div>


            {/* Web page management here */}

        <div
          className="nav flex-column nav-tabs text-center ml-2 mt-3"
          style={{
            border:"1px solid black"
          }}

          role="tablist"
          aria-orientation="vertical"
        >
          
           <h4 className='p-2 bg-red-700 text-white'>Web-Page Management</h4>
          <Nav.Link

            data-mdb-tab-init
            className="tabsHover"
            onClick={()=>setKeys(13)}
          >
            Hero Setting
          </Nav.Link>
          <Nav.Link

            data-mdb-tab-init
            className="tabsHover"
            onClick={()=>setKeys(14)}
          >
            Banner Setting
          </Nav.Link>
          <Nav.Link

            data-mdb-tab-init
            className="tabsHover"
            onClick={()=>setKeys(15)}
          >
            Enter Client
          </Nav.Link>
          <Nav.Link

            data-mdb-tab-init
            className="tabsHover"
            onClick={()=>setKeys(16)}
          >
            Client List
          </Nav.Link>
          <Nav.Link

            data-mdb-tab-init
            className="tabsHover"
            onClick={()=>setKeys(17)}
          >
            Enter Partners
          </Nav.Link>
          <Nav.Link

            data-mdb-tab-init
            className="tabsHover"
            onClick={()=>setKeys(18)}
          >
            Partners List
          </Nav.Link>
          <Nav.Link

            data-mdb-tab-init
            className="tabsHover"
            onClick={()=>setKeys(19)}
          >
            About Us
          </Nav.Link>
          <Nav.Link

            data-mdb-tab-init
            className="tabsHover"
            onClick={()=>setKeys(20)}
          >
            Contact Us
          </Nav.Link>

          
        </div>
      </div>

      <div className="col-9">
        <div className="tab-content" >
          {keys === 1 &&
            <div
            className="tab-pane fade show active"
            role="tabpanel"
          >

           <ProductEntry/>
          </div>}

          {keys === 2 &&
            <div
            className="tab-pane fade show active"
            role="tabpanel"
          >

           <ProductList/>
          </div>}
       
          {keys === 3 &&
            <div
            className="tab-pane fade show active"
            role="tabpanel"
          >

           <AddProductLine/>
          </div>}

          {keys === 4 &&
            <div
            className="tab-pane fade show active"
            role="tabpanel"
          >

           <ProductLineList/>
          </div>}
          {keys === 15 &&
            <div
            className="tab-pane fade show active"
            role="tabpanel"
          >

          <AddClient/>
          </div>}
          {keys === 17 &&
            <div
            className="tab-pane fade show active"
            role="tabpanel"
          >

          <AddPartner/>
          </div>}

          
        </div>
      </div>
    </div>
  );
};

