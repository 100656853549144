import React from 'react'
import Header from './header'
import SideMenue from './SideMenue'
import "./Admin.css"




export default function AdminPanel() {
  return (
    <div>
<Header/>
<SideMenue/>


        
    </div>

  )
}
