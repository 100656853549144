/* eslint-disable */
import React from 'react';
import "./Footer.css"
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <footer className="Footer">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap text-left lg:text-left">
          <div className="w-full lg:w-6/12 px-4">
            <h4 className="text-3xl font-semibold text-blueGray-700">Bio Scientific Technology</h4>
            <h5 className="text-3xl font-semibold text-blueGray-700">Let's keep in touch!</h5>
            <h6 className="text-lg mt-0 mb-2 text-blueGray-600">
              Find us on any of these platforms, we respond 1-2 business days.
            </h6>
            <div className="mt-6 lg:mb-0 mb-6">

              <button className="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
                <i className="fas fa-home"></i> {/* Example of solid style icon */}

              </button>
              <button className="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">

                <i className="far fa-envelope"></i> {/* Example of regular style icon */}

              </button>
              <button className="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">

                <i className="fab fa-facebook"></i>
                {/* Example of brand style icon */}
              </button>
              <button className="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">

                <i className="fab fa-instagram"></i>
                {/* Example of brand style icon */}
              </button>
              <button className="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">

                <i className="fab fa-twitter"></i> {/* Example of brand style icon */}
              </button>


            </div>
          </div>
          <div className="w-full lg:w-5/12 px-4">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-4/12 px-4 ml-auto">
                <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">Useful Links</span>
                <ul className="list-unstyled cursor-pointer">
                  <li>
                    <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" >Products</a>
                  </li>
                  <li>
                    <Link to="/ContactUs" className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm">Contact Us</Link>
                  </li>

                  <li>
                  <Link to="/About" className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm">About Us</Link>
                  </li>
                  <li>
                    <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" >Blog</a>
                  </li>

                </ul>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">Our Address</span>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13287.827477992181!2d73.0625766!3d33.6323603!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df950007592781%3A0x1145ff9c50c18e0c!2sBio%20Scientific%20Technology!5e0!3m2!1sen!2s!4v1724171334339!5m2!1sen!2s" width="250" height="300" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-blueGray-300" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-blueGray-500 font-semibold py-1">
              Copyright © <span id="get-current-year">2024-25 </span>

              Bio Scientific Technology <br /> <span className="text-blueGray-500 hover:text-blueGray-800c">Developed by Rana Muhammad Arslan <br /> Soft Solution</span>.

            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
