/* eslint-disable */
import React from 'react'
import welcome from "../../asset/Welcome.PNG" 


export default function Hero() {
  return (
   <div className="px-6 h-full w-full lg:px-8 ">
    <div>

    <img src={welcome} alt="" className='rounded-xl filter brightness-110 contrast-110 opacity-100 saturate-200	sm:w-svw h-full'/>
 
    </div>
  </div>
  )
}
