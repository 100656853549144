/* eslint-disable */
import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { request } from '../../api/fetchAPI';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { FaHouse } from "react-icons/fa6";
import { Alert, Space } from 'antd';
import { Flex, Spin } from 'antd';
import "./SignUp.css"


export default function SignUp() {

  const [state, setState] = useState()
  const [photo, setPhoto] = useState("")
  const [button, setButton] = useState(true);
  const [error, setError] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate()
  const HandleState = (e) => {
    setState((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

const registerHandler = async (e) => {
  e.preventDefault();
  try {
    setButton(false)
    let filename = null;
    if (photo) {

      const formData = new FormData();
      filename = crypto.randomUUID() + photo.name;
      formData.append("filename", filename);
      formData.append("image", photo);

      await fetch(`https://api.bioscientifictech.com/upload/image`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: formData,
      });
    


    await request("/auth/register", "POST", { "Content-Type": "application/json" },
     { ...state, img:filename });
     setButton(true)
    navigate("/Login");
    }else {
        setError(true)
        setButton(true)
      }
  } catch (error) {
    console.log(error);
  }
};

const ShowPassword = () => {
  var x = document.getElementById("myInput");
  if (x.type === "password") {
    x.type = "text";
  } else {
    x.type = "password";
  }
};

  return (
    <section className="overflow-hidden">


    <div className="container px-4 py-20 px-md-5 text-center text-lg-start my-5">
            <FaHouse className='text-3xl text-white cursor-pointer bg-indigo-800 rounded-xl p-2' onClick={() => navigate("/")} />
            <div className="row gx-lg-5 align-items-center mb-5">
              <div  className="col-lg-6 mb-5 mb-lg-0" style={{ color: "hsl(217, 10%, 50.8%)" }}>
                <h4  className="my-2 display-4 fw-bold ls-tight">
                  Thanks For Chosing <br />
                  <span  className="text-primary">Bio Scientific Technology</span>
                </h4>
                <p style={{ color: "hsl(217, 10%, 50.8%)", textAlign:"justify" }} >
                Bio Scientific Technology is a forward-thinking company
                 dedicated to pushing the boundaries of innovation in the
                  field of life sciences. At Bio Scientific Technology, 
                  we are committed to harnessing cutting-edge advancements in
                 technology to drive breakthroughs in biological research, healthcare, and beyond.
                </p>
              </div>
    
              <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
                <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
                <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>
    
                <div className="card w-full bg-glass">
    
                  <div className="card-body px-4 py-5 px-md-5">
                    <form onSubmit={registerHandler}>
                      <Space
                        hidden={error === false}
                        direction="vertical"
                        style={{
                          width: '100%',
                          marginBottom: "1rem"
                        }}
                      >
                        <Alert message="Please Fill All Fields" type="error" showIcon closable />
                      </Space>
                      <div className="row">
    
                        <div className="col-md-6 mb-4">
                          <div className="form-outline">
                            <label className="form-label" for="form3Example1">First name</label>
                            <input type="text" id="form3Example1" className="form-control" name="fristName" placeholder='Enter Frist Name'
                              onChange={HandleState} />
    
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <div className="form-outline">
                            <label className="form-label" for="form3Example2">Last name</label>
                            <input type="text" id="form3Example2" className="form-control" name="lastName" placeholder='Enter Last Name'
                              onChange={HandleState} />
    
                          </div>
                        </div>
                      </div>
    
    
                      <div className="form-outline mb-4">
                        <label className="form-label" for="form3Example3">Email address</label>
                        <input type="email" placeholder='example@xyz.com' name='userEmail' className="form-control" onChange={HandleState} />
    
                      </div>
                      <div className="form-outline mb-4">
                        <label className="form-label" for="form3Example3">Phone Number</label>
                        <input type="text"
                          id="phone-input"
                          pattern="[0-9]{4}[0-9]{7}"
                          placeholder="0317-XXXXXXX"
                          required className="form-control"
                          name="phoneNumber"
                          onChange={HandleState} />
    
                      </div>
                      <div className="form-outline mb-4">
                        <label className="form-label" for="form3Example2">Upload Picture (JPG, PNG)</label>
                        <input
                          className="form-control"
                          type="file"
                          id="photo"
                          onChange={(e) => setPhoto(e.target.files[0])} />
    
                      </div>
    
                      <div className="form-outline mb-4">
                        <label className="form-label" for="form3Example4">Password</label>
    
                        <input type="password" name='userPassword'
                          id="myInput"
                          className="form-control" placeholder='******'
                          onChange={HandleState} />
                        <div className='pt-2'>
                          Show Password
                          <input
                            type="checkbox"
                            className="form-check-input ml-1 "
                            id="gridCheck"
                            onClick={ShowPassword}
                          />
                        </div>
    
    
                      </div>
    
    
    
    
                      <div className='text-blue-900 cursor-pointer mb-4' onClick={() => navigate("/Login")}>
                        Already Have Account?
                      </div>
    
                      <div className='mb-3'>
                        {button &&
                          <button className="btn btn-primary btn-block w-24" >
                            Sign Up
                          </button>}
                        {button === false &&
                          <span className='p-2 rounded-full' style={{ border: "1px solid blue" }}>
                            Please wait
                            <Spin className='ml-2' />
                          </span>
                        }</div>
    
    
    
    
                      <div className="text-center">
                        <p>or sign up with:</p>
                        <button type="button" className="btn btn-link btn-floating mx-1">
                          <i className="fab fa-facebook-f"></i>
                        </button>
    
                        <button type="button" className="btn btn-link btn-floating mx-1">
                          <i className="fab fa-google"></i>
                        </button>
    
                        <button type="button" className="btn btn-link btn-floating mx-1">
                          <i className="fab fa-twitter"></i>
                        </button>
    
                        <button type="button" className="btn btn-link btn-floating mx-1">
                          <i className="fab fa-github"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
  )
}
