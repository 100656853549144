/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import Draggable from 'react-draggable';
import { useSelector } from "react-redux";
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import { WarningOutlined } from '@ant-design/icons';
import { AiOutlineEdit, AiFillDelete } from "react-icons/ai"
import { message } from "antd";
import { request } from '../../api/fetchAPI';
import { FaDotCircle } from "react-icons/fa";



export default function ProductLineList() {
  const [products, setProducts] = useState([])
  const [messageApi, contextHolder] = message.useMessage();
  const [deleteId, setdeleteId] = useState("")
  const { token } = useSelector((state) => state.auth)

  const errorb = () => {
    messageApi.open({
      type: 'error',
      content: 'Unexpected Error',
    });
  };

  const productStatusHandler = async(id, status)=>{
    try {
      
      await request(`/product/ProductStatus/${id}`, "PUT",{
        Authorization: `Bearer${token}`,
        "Content-Type": "application/json"

      },
      {isActive:status})
      fetchProductData()

    } catch (error) {
      
    }
  }

  //search Challan Data
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
          zIndex: 999
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            className='bg-blue-700'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });


  const fetchProductData = async () => {
    try {
       const Data = await request("/product/getAll/productLine","GET")
      setProducts(Data) 
     
    } catch (error) {
      errorb()
      console.error(error)
    }

  }

  const productDatacolumns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: "5%"
    },
   
  
    {
      title: 'Product Line',
      dataIndex: 'Product',
      key: 'Product',
      width: "40%",
      ...getColumnSearchProps('Product'),

    },
    
  
   
    {
      title: 'Type',
      dataIndex: 'Type',
      key: 'Type',
      width: "20%",


    },
 
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      width: "10%",


    },

    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
      width: "10%"
    },

  ];

  let productData = [];
products.forEach((productsData, index) => {
    productData.push({
      key: productsData._id,
      index:++index,
      Product:productsData.productLine,
      Type:productsData.productLineType,
     
      Status:productsData.isActive === true ? <FaDotCircle className='text-lime-500 cursor-pointer' onClick={()=>{productStatusHandler(productsData._id, false)}}/> 
      : <FaDotCircle className='text-red-500 cursor-pointer' onClick={()=>{productStatusHandler(productsData._id, true)}}/>,
      Amount:productsData.productPrice + ".00",
    
      Action: <div className='row'><AiOutlineEdit className='col' style={{ fontSize: "20px", cursor: "pointer" }} />
      <AiFillDelete className='col ml-12' style={{ fontSize: "20px", cursor: "pointer" }} onClick={() => { dele_openModal(); setdeleteId(productsData._id) }} /> </div>,
       description:
       <div className='row'>
        <div className='col'>
      {productsData.productLineAbout}
        </div>
        

       </div>


    })

  })

  

  const deleteHandler = async (id) => {
    try {
      await request(`/fee/delete/Recipt/${id}`, "DELETE", {
        Authorization: `Bearar ${token}`,
        "Content-Type": "application/json"
      })

      fetchProductData()
    } catch (error) {
      console.log(error)
    }
  }
  const [dele_open, dele_setOpen] = useState(false);
  const [dele_disabled, dele_setDisabled] = useState(true);
  const [dele_bounds, dele_setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const dele_draggleRef = useRef(null);

  const dele_openModal = () => {
    dele_setOpen(true);
  };

  const dele_handleOk = (e) => {
    
    dele_setOpen(false);
  };

  const dele_handleCancel = (e) => {
    dele_setOpen(false);
  };

  const dele_onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = dele_draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    dele_setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };





  useEffect(() => {
    fetchProductData()
    // eslint-disable-next-line 
  }, [])

  return (
    <div className='container challanPanel'>
      {contextHolder}
      <h3>Product Line List</h3>
      <Table
          expandable={{
            expandedRowRender: (record) => (
              <p
                style={{
                  margin:0,
                }}
              >
                {record.description}
              </p>
            ),
            rowExpandable: (record) => record.name !== 'Not Expandable',
          }}
        columns={productDatacolumns}
        dataSource={productData}
      />


      <>
        {/* Delete Model */}
        <Modal
          title={
            <div
              style={{
                width: '100%',
                cursor: 'move',
                color: "red",
                textDecoration: "underline",
                fontWeight: 'bold'
              }}
              onMouseOver={() => {
                if (dele_disabled) {
                  dele_setDisabled(false);
                }
              }}
              onMouseOut={() => {
                dele_setDisabled(true);
              }}

              onFocus={() => { }}
              onBlur={() => { }}
            // end
            >
              <WarningOutlined />Data Deletion - WARNING!
            </div>
          }
          open={dele_open}
          onOk={() => { dele_handleOk(); deleteHandler(deleteId) }}
          onCancel={dele_handleCancel}
          modalRender={(modal) => (
            <Draggable
              disabled={dele_disabled}
              bounds={dele_bounds}
              nodeRef={dele_draggleRef}
              onStart={(event, uiData) => dele_onStart(event, uiData)}
            >
              <div ref={dele_draggleRef}>{modal}</div>
            </Draggable>
          )}
        >
          <p>
            <strong>Do You Want To Delete This Data</strong>
          </p>
          <p><strong>Sugguestion </strong>: Make Sure All Related Data Already Deleted</p>
        </Modal>
      </>
    </div>
  )
}

