import React, { useEffect, useState } from 'react';
import {  Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import Loader from './components/Loader/Loader';
import AppHeader from './components/AppHeader/AppHeader';
import Hero from './components/Hero/Hero';
import Blog from "./components/Blog/Blog"
import OurProductLine from './components/OurProductLine/OurProductLine';
import Stat from './components/Stat/Stat';
import Client from './components/Client/Client';
import Partners from './components/Partners/Partners';
import Product from './components/Products/Products';
import ProductPreview from './components/productPreview/productPreview';
import NEWSLetter from './components/NEWSLetter/NEWSLetter';
import About from './components/About/About';
import ContactUs from './components/ContactUs/ContactUs';
import OurPartner from './components/OurPartner/OurPartner';
import OurClient from './components/OurClient/OurClient';
import ProductListByLine from "./components/ProductListByLine/ProductListByLine"
import Login from './components/Login/Login';
import SignUp from './components/SignUp/SignUp';
import Footer from './components/Footer/Footer';
import Page404 from './components/Page404/Page404';
import LoginError from "./components/LoginError/LoginError"
import Quotation from "./components/Quotation/Quotation"
import AdminPanel from './components/AdminPanel/AdminPanel';
import whatsAppLogo from "../src/asset/whatsappLogo.jpg"
import "./App.css";





export default function App() {

  const {user} = useSelector((state)=>state.auth)

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate data fetching or any other async operation
    setTimeout(() => {
      setIsLoading(false);
    }, 3000); 
  }, []);


 


  return (

    <div>
{ isLoading ?
   <div>
   <Loader />
   </div>

   :

   <div className='APP'>
   <Routes>
     <Route path='/' element={<>
       <AppHeader />
       <Hero />
       <Blog />
       <OurProductLine />
       <Stat />
       <Client/>
       <Partners/>
       <Product /> 
       <NEWSLetter />
       <Footer />
     </>} />
     <Route path='/OurPartner' element={<>
       <AppHeader />
       <OurPartner />
       <Footer />
     </>} />
     <Route path='/OurClient' element={<>
       <AppHeader />
       < OurClient/>
       <Footer />
     </>} />
     <Route path='/about' element={<>
       <AppHeader />
       <About />
       <Footer />
     </>} />
     <Route path='/Product-Preview/:id' element={<>
       <AppHeader />
       <ProductPreview />
       <Footer />
     </>} />

     <Route path='/Product-Preview/:id' element={<>
       <AppHeader />
       <ProductPreview />
       <Footer />
     </>} />
     <Route path='/Product-Preview/:id' element={<>
       <AppHeader />
       <ProductPreview />
       <Footer />
     </>} />

     <Route path='/Products/:id' element={<>
       <AppHeader />
       <ProductListByLine />
       <Footer />
     </>} />

     <Route path='/Quotation/:id' element={
     <>
        {user ?
         <>
        <AppHeader />
       <Quotation/>
       <Footer />
       </> 
         :
         <LoginError/>

         
       }
        
   

       
       </>
   } />

     <Route path='/ContactUs' element={<>
       <AppHeader />
       <ContactUs />
       <Footer />
     </>} />
     <Route path='/Login' element={<>
     {user ?
       <Navigate to="/"/>
       :
       <Login />
       }
     </>} />
     <Route path='/SignUp' element={
     <>
      {!user ?
       <SignUp />
       : 
       <Navigate to="/"/>
      }

     </>} />
   
        <Route path='/Panel' element={user && user.role === "admin" ? <AdminPanel/> : <Page404 />}/>

    
     
     <Route path='*' element={<Page404 />} />
   </Routes>



<div className="fixed-bottom p-3 right-100">
<a href="https://wa.me/+923315595260" target="_blank">
<img src={whatsAppLogo} alt="Whatsapp Logo" className='absolute bottom-0 right-0 h-16 w-16 m-4 rounded-xl'/>
</a> 
</div>
</div> 

}
   
  
   
    </div> 

  );
}
