/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { request } from "../../api/fetchAPI";
import ClientImg from "../../asset/ClientPIc.PNG"
export default function OurClient() {
    const [partners, setPartners] = useState([])

    const fetchPartner =async ()=>{
        try {
          const data = await request("/webPage/getAll/Client", "GET")
              setPartners(data)
             
              
        } catch (error) {
          console.log(error)
        }
      }

    useEffect(()=>{
        fetchPartner()
    },[])
    
  return (
    <div className="bg-white mb-9 ">
      <div className='flex flex-col h-52 justify-center items-center  font-semibold text-white bg-indigo-700'>
     <h4 className='text-3xl'>Our Loyal Client</h4>
     <h6> Strong Relationships with Clients is Key</h6>

     </div>
     <div className='flex justify-center items-center mb-5'>
     <img src={ClientImg} alt="" className='h-[35rem] w-full '/>
     </div>
      <div className="mx-auto max-w-7xl mt-9 px-6 lg:px-8">
     
        <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center  lg:grid-cols-3">
          {partners.map((stat) => (
            <div key={stat._id} className="mx-auto h-96 flex max-w-xs flex-col cursor-pointer gap-y-4 hover:bg-slate-300" style={{boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px", padding:"1rem", width:"110%", borderRadius:"15px"}}>
              <img className='rounded-xl h-72' src={stat.clientImg ? `http://localhost:5000/images/${stat.clientImg }` : "NULL"} alt="" />
             
              <p className="order-first text-lx font-semibold tracking-tight text-gray-900 sm:text-lx">
              {stat.clientName}
              </p>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
