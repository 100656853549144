/* eslint-disable */
import { useEffect, useState } from 'react'
import { request } from '../../api/fetchAPI';
import { StarIcon } from '@heroicons/react/20/solid'
import { useNavigate, useParams } from 'react-router-dom';
import { FaStar } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';




export default function ProductPreview() {
  const [state, setState] = useState()
  const [product, setProductState] = useState([])
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const naviget = useNavigate()
  const {id} = useParams()

  const stateHandler = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };


  
  const fetchProducts = async (Id)=>{
    try {
     
      const productData = await request(`/product/get/Products/${Id}`,"GET")
   
      setProductState(productData)
      
    } catch (error) {
      console.log(error)
    }
  }

  

  const reviews = { href: '#', average: 4, totalCount: 10 }
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  

  
useEffect(()=>{
  fetchProducts(id)
   window.scrollTo(0, 0);
},[id])

 
  return (
    <div className="bg-white">
      <div className="pt-6">
        <nav aria-label="Breadcrumb">
          <ol role="list" className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
           
              <li key={product._id}>
                <div className="flex items-center">
                  <span className="mr-2 text-sm font-medium text-gray-900">
                    {product?.productName}
                  </span>
                  <svg
                    width={16}
                    height={20}
                    viewBox="0 0 16 20"
                    fill="currentColor"
                    aria-hidden="true"
                    className="h-5 w-4 text-gray-300"
                  >
                    <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                  </svg>
                </div>
              </li>
         
            <li className="text-sm">
              <span aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                {product?.productName}
              </span>
            </li>
          </ol>
        </nav>

        {/* Image gallery */}
        
        <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 lg:px-8">
          <div className="aspect-h-4 aspect-w-3 hidden overflow-hidden rounded-lg lg:block">
            <img
              src={product.img ? `https://api.bioscientifictech.com/images/${product.img4}` : "NULL"}
              
              className="h-full w-full object-cover object-center"
            />
          </div>
          <div className="hidden lg:grid lg:grid-cols-1 lg:gap-y-8">
            <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
              <img
                src={product.img ? `https://api.bioscientifictech.com/images/${product.img3}` : "NULL"}
               
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
              <img
                src={product.img ? `https://api.bioscientifictech.com/images/${product.img2}` : "NULL"} 
               
                className="h-full w-full object-cover object-center"
              />
            </div>
          </div>
          <div className="aspect-h-5 aspect-w-4 lg:aspect-h-4 lg:aspect-w-3 sm:overflow-hidden sm:rounded-lg">
            <img
              src={product.img ? `https://api.bioscientifictech.com/images/${product.img}` : "NULL"}
              
              className="h-full w-full object-cover object-center"
            />
          </div>
        </div>

    

        {/* Product info */}
        <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
          <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{product?.productName}</h1>
            <p className="text-l font-bold tracking-tight text-gray-500 sm:text-l">{product?.manufacturedBy}</p>
          </div>

          {/* Options */}
          <div className="mt-4 lg:row-span-3 lg:mt-0">
            <h2 className="sr-only">Product information</h2>
           

            {/* Reviews */}
            <div className="mt-6">
              <h3 className="sr-only">Reviews</h3>
              <div className="flex items-center">
                <div className="flex items-center">
                  {[0, 1, 2, 3, 4].map((rating) => (
                    <StarIcon
                      key={rating}
                      className={classNames(
                        reviews.average > rating ? 'text-gray-900' : 'text-gray-200',
                        'h-5 w-5 flex-shrink-0'
                      )}
                      aria-hidden="true"
                    />
                  ))}
                </div>
                <p className="sr-only">{reviews.average} out of 5 stars</p>
                <a href={reviews.href} className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500">
                  {reviews.totalCount} reviews
                </a>
              </div>
            </div>

            <form className="mt-3">
              
            

              {/* Sizes */}
        
              <div className="mt-3">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium text-gray-900">Category</h3>
                  
                  
                </div>

                  <div className="grid grid-cols-4 sm:grid-cols-8 lg:grid-cols-4">
                  {product.productCategory ? product.productCategory.productLineType : "NULL"
                  }
                  </div>
               
              </div>
              <div className="mt-3">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium text-gray-900">Product/Cat Code</h3>
                  
                  
                </div>

                  <div className="grid grid-cols-6  sm:grid-cols-8 lg:grid-cols-4">
                  {product.productCode}
                  </div>
               
              </div>
       
          
              <div className="mt-3">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium text-gray-900">Manufactured By</h3>
                  
                  
                </div>

                  <div className=" sm:grid-cols-8 lg:grid-cols-4">
                  {product.manufacturedBy}
                  </div>
               
              </div>

              <button
                type="submit"
                className="mt-10 flex w-full items-center justify-center rounded-md border 
                border-transparent bg-lime-600 px-8 py-3 text-base font-medium text-white
                 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                 onClick={()=>naviget(`/Quotation/${id}`)}
              >
                Get Quotation
              </button>
              <a
                
                href={product.externalLink}
                target="_blank"
                className="mt-3 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                For More info Click Here
              </a>
            </form>
          </div>

          <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
            {/* Description and details */}
            <div>
              <h3 className="sr-only">Description</h3>

              <div className="space-y-6">
                <p className="text-base text-gray-900">{product?.productDescription}</p>
              </div>
            </div>

            <div className="mt-10">
              <h3 className="text-sm font-medium text-gray-900">Usage</h3>

              <div className="mt-4">
                <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                  {product?.productUsage?.map((productUsage) => (
                    <li key={productUsage} className="text-gray-400">
                      <span className="text-gray-600">{productUsage}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="mt-10">
              <h2 className="text-sm font-medium text-gray-900">Details</h2>

              <div className="mt-4 space-y-6">
                <p className="text-sm text-gray-600">{product.productDetails}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='p-5 m-5 bg-slate-300 rounded-xl'>
                  <h3 className='text-xl font-semibold'>Please tell us about ourselves.</h3>
                  <form className="lg:col-span-2">
                  <div>
      {[...Array(5)].map((star, i) => {
        const ratingValue = i + 1;

        return (
          <label key={i}>
            <input
              type="radio"
              name="rating"
              style={{display:"none"}}
              value={ratingValue}
              onClick={() => setRating(ratingValue)}
            />
            <FaStar
              className="star"
              color={ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
              size={40}
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(0)}
            />
          </label>
        );
      })}
      <p>The rating is {rating}.</p>
    </div>
                <div className="lg:col-span-2 mt-2">
                  <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                    <div className="md:col-span-2">

                      <div  className="form-floating mb-2 md-2">
                        <input type="text" className="form-control" id="floatingInput inputState" name="productName"
                          placeholder="e.g Kits" onChange={stateHandler} />
                        <label for="floatingInput">Product Name</label>
                      </div>

                    </div>
                    <div className="md:col-span-2">
                      <div  className="form-floating mb-2 md-2">
                        <input type="text" className="form-control" id="floatingInput inputState" name="productCode"
                          placeholder="e.g Kits" onChange={stateHandler} />
                        <label for="floatingInput">Product Code</label>
                      </div>

                    </div>




                    <div className="md:col-span-4">
                      <div  className="form-floating mb-2 md-3">
                        <textarea type="text" className="form-control" id="floatingInput inputState" name="productDetails"
                          placeholder="e.g Kits" onChange={stateHandler} />
                        <label for="floatingInput">Subject</label>
                      </div>
                    </div>

                    <div className="md:col-span-4">
                      <div  className="form-floating mb-2 md-3">
                        <textarea type="text" className="form-control" id="floatingInput inputState" name="productDescription"
                          placeholder="e.g Kits" onChange={stateHandler} />
                        <label for="floatingInput">Message</label>
                      </div>
                    </div>

                

                 
                   

                    <div className="md:col-span-5">
                      <div className="inline-flex items-end">
                        <button className="bg-blue-700 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
      </div>
      <div className='p-5 m-5 bg-slate-300 rounded-xl'>
      <h3 className='text-xl font-semibold'>Cutomer Reviews</h3>
    </div>
    </div>
  )
}

