/* eslint-disable */
import React, { useEffect, useState } from "react";
import { request } from "../../api/fetchAPI";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Partners.css"



export default function Partners() {
const [partners ,setPartners] = useState([])


const fetchPartner =async ()=>{
  try {
    
    const data = await request("/webPage/getAll/Partner", "GET")
        setPartners(data)
    
        
  } catch (error) {
    console.log(error)
  }
}

useEffect(()=>{
  fetchPartner()
},[])

  const [slidesToShow,setSlidesToShow] = useState(3)

useEffect(()=>{
  const handleResize = () =>{
    if(window.innerWidth <= 768){
      setSlidesToShow(1)
    }else{
      setSlidesToShow(3)
    }
  }
  handleResize()
  window.addEventListener("resize", handleResize);
  return() =>{
    window.removeEventListener("resize", handleResize)
  }

},[])



const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: slidesToShow,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000
};
  return (
    <div>
  
      <div className="w-3/4 m-auto bg-white" >
      <h2 className="flex flex-row justify-center align-middle mt-20 w-30 rounded-xl h-10 text-white bg-[#FF204E] font-bold text-3xl	">Our Partners</h2>
<div className="mt-20 text-blue-900 " onClick={()=>fetchPartner()}> 
<Slider {...settings}>
  {partners.map((data) => (
  <div key={data._id} className=" h-[450px] text-black rounded-xl Clientcard">
    <div className="h-56 rounded-t-xl  flex justify-center items-center bg-rose-600 ">
      <img src={data.partnerImg ? `https://api.bioscientifictech.com/images/${data.partnerImg}`: "NULL"} alt="" className="h-44 w-48 rounded-xl border-white"/>
    </div>
    <div className="flex flex-col justify-center items-center gap-4 p-4 ">
      <p className="text-xl font-semibold">{data.partnerName}</p>
      <p>{data.Description}</p>
      
    </div>
  </div>
  
))}
</Slider>
</div>
    </div>
    </div>
    
  );
}



