import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { request } from '../../api/fetchAPI';
import {  useParams } from 'react-router-dom';

const Quotation = () => {
  const [state, setState] = useState([]);
  const [product, setProductState] = useState([])
  const [productDlt, setProductDlt] = useState([])
  const { token } = useSelector((state) => state.auth);
  const {id} = useParams()
  const stateHandler = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };



  const QuotationHandler = async (e) => {
    e.preventDefault();

    try {
      await request("/product/RFQ", "POST", {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }, { ...state, ProductId:id });

  
    } catch (error) {
      console.error(error);
    }
  };


  const fetchProducts = async (Id)=>{
    try {
     
      const productData = await request(`/product/get/Products/${Id}`,"GET")
      setProductState(productData)
      
    } catch (error) {
      console.log(error)
    }
  }
  const fetchProductsDetails = async ()=>{

    try {
      const data = await request("/product/get/ProductCode", "POST", {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }, { ...state});
      setProductDlt(data)
  
    } catch (error) {
      console.error(error);
    }
  };
  


  useEffect(()=>{
    fetchProducts(id)
   window.scrollTo(0, 0);
  },[id])

  return (

    <div className="min-h-screen p-6 bg-gray-100 flex items-center justify-center">
      <div className="container max-w-screen-lg mx-auto">
        <div>
          <h2 className="font-semibold text-xl text-gray-600">Quotation Required</h2>


          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div className=" lg:grid-cols-3">

              <form onSubmit={QuotationHandler} className="lg:col-span-2">
                <div className="lg:col-span-2">
                  <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                    
                  <div className="md:col-span-4">

                      <div  className="form-floating mt-2 mb-2 md-2 flex flex-row">
                  
                      <input type="text" className="form-control" id="floatingInput inputState" name="searchItem"
                          placeholder="e.g Kits"  onChange={stateHandler}/>
                          <button className='btn -ml-20 bg-red-500 rounded-xl text-zinc-50 hover:bg-red-400' onClick={()=>fetchProductsDetails()}>Search</button>
                        <label for="floatingInput">Search Cat/Case No or Product Name</label>
                      </div>

                    </div>
                    <div className="md:col-span-2">
                      <div  className="form-floating mb-2 md-2">
                        <input type="text" className="form-control" id="floatingInput inputState" name="productCode"
                          placeholder="e.g Kits"  value={productDlt.ProductName ??  ""} readOnly />
                        <label for="floatingInput">Product Name</label>
                      </div>

                    </div>
                    <div className="md:col-span-2">
                      <div  className="form-floating mb-2 md-2">
                        <input type="text" className="form-control" id="floatingInput inputState" name="productCode"
                          placeholder="e.g Kits"  value={productDlt.CatNo ??  ""} readOnly />
                        <label for="floatingInput">Cat No</label>
                      </div>

                    </div>
                    <div className="md:col-span-2">
                      <div  className="form-floating mb-2 md-2">
                        <input type="text" className="form-control" id="floatingInput inputState" name="productCode"
                          placeholder="e.g Kits"  value={productDlt.CaseNo ??  ""} readOnly />
                        <label for="floatingInput">Case No</label>
                      </div>

                    </div>
                    <div className="md:col-span-4">

                      <div  className="form-floating mb-2 md-2">
                        <input type="text" className="form-control" id="floatingInput inputState" name="productName"
                          placeholder="e.g Kits"  value={product.productName} readOnly/>
                        <label for="floatingInput">Product Field Name</label>
                      </div>

                    </div>
                    <div className="md:col-span-2">
                      <div  className="form-floating mb-2 md-2">
                        <input type="text" className="form-control" id="floatingInput inputState" name="productCode"
                          placeholder="e.g Kits"  value={product.productCategory ? product.productCategory.productLineType : ""} readOnly />
                        <label for="floatingInput">Product Category</label>
                      </div>

                    </div>
                    <div className="md:col-span-2">
                      <div  className="form-floating mb-2 md-2">
                        <input type="text" className="form-control" id="floatingInput inputState" name="productCode"
                          placeholder="e.g Kits"  value={product.productCode} readOnly />
                        <label for="floatingInput">Product Field Code</label>
                      </div>

                    </div>



                  

                    <div className="md:col-span-2">
                      <div  className="form-floating mb-2 md-3">
                        <input type="text" className="form-control" id="floatingInput inputState" name="manufacturedBy"
                          placeholder="e.g Kits"   value={product.manufacturedBy} readOnly/>
                        <label for="floatingInput">Manufacturer By</label>
                      </div>
                    </div>


                


                    <div className="md:col-span-2">
                      <div  className="form-floating mb-2 md-3">
                        <input type="text" className="form-control" id="floatingInput inputState" name="Subject"
                          placeholder="e.g Kits"  onChange={stateHandler} />
                        <label for="floatingInput">Subject</label>
                      </div>
                    </div>

                    <div className="md:col-span-4">
                      <div  className="form-floating mb-2 md-3">
                        <textarea type="text" className="form-control" id="floatingInput inputState" name="Message"
                          placeholder="e.g Kits"  onChange={stateHandler} />
                        <label for="floatingInput">Message</label>
                      </div>
                    </div>

                    <div className="md:col-span-5">
                      <div className="inline-flex items-end">
                        <button className="bg-blue-700 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
};

export default Quotation;







