/* eslint-disable */

import React from 'react'

export default function Stat() {
    const stats = [
        { id: 1, name: '100+ Projects SucessFully Done', value: 'Projects Done' },
        { id: 2, name: '300+ Happy and Loyal clients', value: 'Happy Clients' },
        { id: 3, name: 'We have 100% orignal products', value: 'Orignal Products' },
      ]
  return (
    <div className="bg-white  py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center  lg:grid-cols-3">
          {stats.map((stat) => (
            <div key={stat.id} className="mx-auto flex max-w-xs flex-col cursor-pointer gap-y-4 hover:bg-slate-300" style={{boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px", padding:"1rem", width:"110%", borderRadius:"15px"}}>
              <dt className="text-base leading-7 text-gray-600">{stat.name}</dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                {stat.value}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
