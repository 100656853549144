/* eslint-disable */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { login } from '../../redux/authSlice'
import { request } from '../../api/fetchAPI'
import { FaHouse } from "react-icons/fa6";
import { Alert, Space } from 'antd';
import { Flex, Spin } from 'antd';
import "./Login.css"

export default function Login() {

  const [Email, setEmail] = useState("")
  const [Password, setPassword] = useState("")
  const [button, setButton] = useState(true);
  const [error, setError] = useState(false);
  const [inputError, setInputError] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const loginHandler = async (e)=>{
    e.preventDefault()
    try {
      setButton(false)
      if(Email === null && Password === null){

        setInputError(true)
      }else if(Email && Password !== null){
      const loginData = await request("/auth/Login", "POST", {"Content-Type":"application/json"}
      ,{Email,Password})
      dispatch(login(loginData))
      setButton(true)
      navigate("/")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const ShowPassword = () => {
    var x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  return (
    <section className="">

      <div className="container px-4 py-20 px-md-5 text-center text-lg-start my-5">
      <FaHouse className='text-3xl text-white cursor-pointer bg-indigo-800 rounded-xl p-2' onClick={() => navigate("/")} />
        <div className="row gx-lg-5 align-items-center mb-5">
          <div  className="col-lg-6 mb-5 mb-lg-0" style={{ color: "hsl(217, 10%, 50.8%)" }}>
            <h4  className="my-2 display-4 fw-bold ls-tight">
              Welcome Back To <br />
              <span  className="text-primary">Bio Scientific Technology</span>
            </h4>
            <p style={{ color: "hsl(217, 10%, 50.8%)", textAlign:"justify" }} >
            Bio Scientific Technology is a forward-thinking company
             dedicated to pushing the boundaries of innovation in the
              field of life sciences. At Bio Scientific Technology, 
              we are committed to harnessing cutting-edge advancements in
             technology to drive breakthroughs in biological research, healthcare, and beyond.
            </p>
          </div>

          <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
            <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
            <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>

            <div className="card w-full bg-glass">
              <div className="card-body px-4 py-5 px-md-5">
                <form onSubmit={loginHandler}>

                <Space
                    hidden={error === false }
                    direction="vertical"
                    style={{
                      width: '100%',
                      marginBottom: "1rem"
                    }}
                  >
                    <Alert  message="Unexpected Error Occurs While Loging" type="error" showIcon closable />
                
                  </Space>
                <Space
                    hidden={inputError === false}
                    direction="vertical"
                    style={{
                      width: '100%',
                      marginBottom: "1rem"
                    }}
                  >
                    
                    <Alert  message="Please Fill All Fields" type="error" showIcon closable />
                  </Space>

                  <div className="form-outline mb-4">
                    <label className="form-label" for="form3Example3">Email address</label>
                    <input type="email" name="Email" id="form3Example3" className="form-control" 
                   onChange={(e) => setEmail(e.target.value)} required/>

                  </div>


                  <div className="form-outline mb-4">
                    <label className="form-label" for="form3Example4">Password</label>
                    <input type="password" name='Password' 
                    id="myInput"
                    className="form-control" onChange={(e) => { setPassword(e.target.value) }} required/>
<div className='pt-2'>
                      Show Password
                      <input
                        type="checkbox"
                        className="form-check-input ml-1 "
                        id="gridCheck"
                        onClick={ShowPassword}
                      />
                    </div>
                  </div>


                  <div className='mb-3'>
                    {button &&
                      <button className="btn btn-primary btn-block w-24" >
                        Login
                      </button>}
                    {button === false &&
                      <span className='p-2 rounded-full' style={{ border: "1px solid blue" }}>
                        Please wait
                        <Spin className='ml-2' />
                      </span>
                    }</div>
                  <div className='flex-row center'>
                    <div className='text-blue-900 cursor-pointer	' onClick={()=>navigate("/signup")}>
                      Dont Have Account? SignUp
                    </div>

                    <div className='text-red-900 cursor-pointer	'>
                      Forget Password?
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
