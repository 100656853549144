import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { request } from '../../api/fetchAPI';

const ProductEntry = () => {
  const [state, setState] = useState({
    productName: "",
    productUsage: [],
    externalLink: "",
    productCategory: "",
    measuringUnit: "",
    productPrice: "",
    manufacturedBy: "",
    stockQuantity: "",
    productDetails: "",
    productCode: "",
    productDescription: "",
    manufacturingDate: "",
    expiryDate: ""
  });
  const [photo, setPhoto] = useState();
  const [photo2, setPhoto2] = useState();
  const [photo3, setPhoto3] = useState();
  const [photo4, setPhoto4] = useState();
  const [category, setCategory] = useState([])

  const { token } = useSelector((state) => state.auth);

  const stateHandler = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const handleAddSubject = () => {
    setState((prevState) => ({
      ...prevState,
      productUsage: [...prevState.productUsage, ""]
    }));
  };

  const handleRemoveSubject = (index) => {
    const updatedSubjects = [...state.productUsage];
    updatedSubjects.splice(index, 1);
    setState((prevState) => ({
      ...prevState,
      productUsage: updatedSubjects
    }));
  };

  const ProductHandler = async (e) => {
    e.preventDefault();

    try {
      let filename = null
      let filename2 = null
      let filename3 = null
      let filename4 = null

      const formData = new FormData();
      const formData2 = new FormData();
      const formData3 = new FormData();
      const formData4 = new FormData();

      if (photo) {
        filename = crypto.randomUUID() + photo.name;
        formData.append("filename", filename);
        formData.append("image", photo);
      }

      await fetch(`https://api.bioscientifictech.com/upload/image`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        method: "POST",
        body: formData
      });

      if (photo2) {
        filename2 = crypto.randomUUID() + photo2.name;
        formData2.append("filename", filename2);
        formData2.append("image", photo2);
      }


      await fetch(`https://api.bioscientifictech.com/upload/image`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        method: "POST",
        body: formData2
      });

      if (photo3) {
        filename3 = crypto.randomUUID() + photo3.name;
        formData3.append("filename", filename3);
        formData3.append("image", photo3);
      }


      await fetch(`https://api.bioscientifictech.com/upload/image`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        method: "POST",
        body: formData3
      });

      if (photo4) {
        filename4 = crypto.randomUUID() + photo4.name;
        formData4.append("filename", filename4);
        formData4.append("image", photo4);
      }

      await fetch(`https://api.bioscientifictech.com/upload/image`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        method: "POST",
        body: formData4
      });

      await request("/product/createProduct", "POST", {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }, { ...state, img: filename, img2: filename2, img3: filename3, img4: filename4 });

      setState({
        productName: "",
        productUsage: [],
        externalLink: "",
        productCategory: "",
        measuringUnit: "",
        productPrice: "",
        manufacturedBy: "",
        stockQuantity: "",
        productDetails: "",
        productCode: "",
        productDescription: "",
        manufacturingDate: "",
        expiryDate: ""
      })

    } catch (error) {
      console.error(error);
    }
  };


  const fetchCategory = async ()=>{

    try {
      const data = await request("/product/get/productLine","GET")
      setCategory(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
    fetchCategory()
  },[])

  return (
    <div className="min-h-screen p-6 bg-gray-100 flex items-center justify-center">
      <div className="container max-w-screen-lg mx-auto">
        <div>
          <h2 className="font-semibold text-xl text-gray-600">Product Entry Form</h2>


          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div className=" lg:grid-cols-3">

              <form onSubmit={ProductHandler} className="lg:col-span-2">
                <div className="lg:col-span-2">
                  <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                    <div className="md:col-span-5">

                      <div class="form-floating mb-2 md-2">
                        <input type="text" className="form-control" id="floatingInput inputState" name="productName"
                          placeholder="e.g Kits" onChange={stateHandler} />
                        <label for="floatingInput">Product Name</label>
                      </div>

                    </div>
                    <div className="md:col-span-2">
                      <div class="form-floating mb-2 md-2">
                        <input type="text" className="form-control" id="floatingInput inputState" name="productCode"
                          placeholder="e.g Kits" onChange={stateHandler} />
                        <label for="floatingInput">Product Code</label>
                      </div>

                    </div>


                    <div className="md:col-span-2">
                      <div class="form-floating mb-2 md-2">

                        <select
                          id="inputState"
                          className="form-control"
                          name="productCategory"
                          placeholder="e.g Kits" onChange={stateHandler}
                        >
                          <option selected hidden>Chose..</option>
                         {category.map((data)=>(
                           <option key={data._id}  value={data._id}>{data.productLine} - {data.productLineType}</option>
                         ))
                         
                         }
                          
                         
                        </select>
                        <label for="floatingInput">Category/Group/Product Line</label>
                      </div>

                    </div>

                    <div className="md:col-span-2">
                      <div class="form-floating mb-2 md-3">
                        <input type="text" className="form-control" id="floatingInput inputState" name="manufacturedBy"
                          placeholder="e.g Kits" onChange={stateHandler} value={state.manufacturedBy} />
                        <label for="floatingInput">Manufacturer By</label>
                      </div>
                    </div>



                    <div className="md:col-span-2">
                      <div class="form-floating mb-2 md-2">
                        <input type="text" className="form-control" id="floatingInput inputState" name="externalLink"
                          placeholder="e.g Kits" onChange={stateHandler} />
                        <label for="floatingInput">External Link(if any)</label>
                      </div>

                    </div>


                    <div className="md:col-span-3 -mt-3">
                      <label for="floatingInput">Add Product Useage</label>
                      <br />
                        {state.productUsage.map((usage, index) => (
                          <div key={index} className="mb-2 col">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Usage"
                              value={usage}
                              onChange={(e) => {
                                const updatedUsage = [...state.productUsage];
                                updatedUsage[index] = e.target.value;
                                setState((prev) => ({ ...prev, productUsage: updatedUsage }));
                              }} assName
                            />
                            <div className="input-group-append">
                              <button
                                className="btn btn-outline-danger mt-2"
                                type="button"

                                onClick={() => handleRemoveSubject(index)}
                              >
                                Remove Input
                              </button>
                            </div>
                          </div>
                        ))}
                        <button
                          className="btn btn-outline-primary"
                          type="button"
                          onClick={handleAddSubject}
                        >
                          Add Product Useage
                        </button>
                      </div>
               

                    <div className="md:col-span-4">
                      <div class="form-floating mb-2 md-3">
                        <textarea type="text" className="form-control" id="floatingInput inputState" name="productDetails"
                          placeholder="e.g Kits" onChange={stateHandler} />
                        <label for="floatingInput">Product Details</label>
                      </div>
                    </div>

                    <div className="md:col-span-4">
                      <div class="form-floating mb-2 md-3">
                        <textarea type="text" className="form-control" id="floatingInput inputState" name="productDescription"
                          placeholder="e.g Kits" onChange={stateHandler} />
                        <label for="floatingInput">Product Description</label>
                      </div>
                    </div>

                
                    <div className="md:col-span-2 ">

                      <div className="mb-3">
                        <label for="formFile" class="form-label">Upload Picture # 1</label>
                        <input className="form-control w-72" name="photo"
                          type="file" id="photo" onChange={(e) => setPhoto(e.target.files[0])} required />



                      </div>

                    </div>
                    <div className="md:col-span-2">

                      <div className="mb-3">
                        <label for="formFile" class="form-label">Upload Picture # 2</label>
                        <input className="form-control w-72" name="photo"
                          type="file" id="photo" onChange={(e) => setPhoto2(e.target.files[0])} required />



                      </div>


                    </div>

                    <div className="md:col-span-2">


                      <div className="mb-3">
                        <label for="formFile" class="form-label">Upload Picture # 3</label>
                        <input className="form-control w-72" name="photo"
                          type="file" id="photo" onChange={(e) => setPhoto3(e.target.files[0])} required />

                      </div>

                    </div>

                    <div className="md:col-span-2">

                      <div className="mb-3">
                        <label for="formFile" class="form-label">Upload Picture # 4</label>
                        <input className="form-control w-72" name="photo"
                          type="file" id="photo" onChange={(e) => setPhoto4(e.target.files[0])} required />

                      </div>
                    </div>


                    <div className="md:col-span-5">
                      <div className="inline-flex items-end">
                        <button className="bg-blue-700 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
};

export default ProductEntry;







