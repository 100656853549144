/* eslint-disable */
import { Fragment, useState, React, useEffect } from 'react'

import { request } from '../../api/fetchAPI';
import { useNavigate } from 'react-router-dom';

  export default function Product() {

    const [products, setProductsLine] = useState([])
    const navigate = useNavigate()

    const fetchProducts = async ()=>{
    try {
      const data = await request("/product/getAll/productLine", "GET")

      setProductsLine(data)
      
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
    fetchProducts()
  },[])

    return (
      <div className='mt-3' style={{background:"#362FD9", color:"white"}}>
                 <h2 className="text-2xl pt- font-bold tracking-tight"
                  style={{backgroundColor:"#362FD9", color:"white", display:'flex', 
                  flexDirection:'row', alignItems:"center", justifyContent:"center"}}>Our Product Line</h2>
        <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
 
  
          <div className="mt-6 grid  grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 mb-5 lg:grid-cols-4 xl:gap-x-8">
            {products.map((product) => (
              <div key={product._id} className=" bg-white w-100 h-96" style={{borderRadius:"15px"}}>
                <div className="h-56 rounded-t-xl bg-[#FBA834] flex justify-center items-center">
      <img src={product.coverImg ? `https://api.bioscientifictech.com/images/${product.coverImg}` : "NULL"} alt="" className="h-44 w-44 rounded " style={{boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", backgroundColor:"white"}}/>
    </div>
              
    <div className=" flex flex-col justify-center items-center -gap-1 p-3 ">
      <p className="text-xl font-semibold text-black">{product.productLine}</p>
      <p className=" text-black h-5 overflow-ellipsis font-light text-justify">{product.productLineAbout}</p>
      <button  className="bg-red-700 hover:bg-red-500 text-white font-bold py-2 px-4 mt-5 rounded-full" onClick={()=>navigate(`/Products/${product._id}`)}>
  Read More
</button>
      
      
    </div>
              
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  