import React, { useEffect, useState } from 'react'
import { request } from '../../api/fetchAPI';
import "./Product.css"
import { useNavigate } from 'react-router-dom'




export default function Product() {
  const [products, setProductState] = useState([])
  const navigate = useNavigate()



const fetchProducts = async ()=>{
  try {

    const productData = await request("/product/get/Products","GET")

    setProductState(productData)
    
  } catch (error) {
    console.log(error)
  }
}


   
  
useEffect(() => {
  fetchProducts(); // Assuming fetchProducts is a function that fetches products
}, []);


  return (
    <div classname="Productcontainer mt-5 ">

<div className="bg-white ">
  
      <div className="mx-auto max-w-2xl  px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">

        <h2 className="text-2xl font-bold tracking-tight text-gray-900">Product Area List</h2>
      
        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 bor sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8" >
          {products.length === 0 ?
          <span className=''>Product list is empty </span>
          :
          products.map((product) => (
            <div key={product._id} className="group relative w-72 p-1 rounded-xl border-solid border-2 border-sky-500" onClick={()=>navigate(`/Product-Preview/${product._id}`)} >
              <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80">
                <img
                  src={product.img ? `https://api.bioscientifictech.com/images/${product.img2}` : "NULL"}
                  alt={product.productName}
                  className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                />
              </div>
              <div className="mt-4 flex justify-between">
                <div>
                  <h3 className="text-sm text-gray-700">
                   
                      <span aria-hidden="true" className="absolute inset-0" />
                      {product.productName}
                  
                  </h3>
                 
                  <p className="mt-1 text-sm text-gray-500">Manufactured By:{ product.manufacturedBy}</p>
                </div>
                
               
              </div>
              <hr className='mt-2 text-black'/>
              <button className='bg-black text-white mt-2 py-2 px-4 font-bold rounded-xl cursor-pointer' onClick={()=>navigate(`/Product-Preview/${product._id}`)}>Preview</button>
              
            </div>
          ))}
        </div>
      </div>
    </div>



    	



    </div>
  )
}
