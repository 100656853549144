import React from "react"
import Side from "../../asset/SideImg.jpg"

export default function Blog() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">Our Expreties</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">We Deals In</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
              Bio Scientific Technology is a leading supplier of 
              innovative solutions for the life sciences industry.
               We are dedicated to empowering researchers and healthcare professionals 
               with the tools they need to advance scientific discovery and improve patient care.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src={Side}
            alt=""
          />
        </div>
        <div className="lg:col-span-2 -mt-10 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
             
              <ul  className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                 
                  <span>
                    <strong className="font-semibold text-gray-900">All Type ELISA Kits:</strong> We offer a comprehensive 
                    range of human ELISA kits for the detection and quantification of various biomarkers,
                     enabling researchers to investigate a wide spectrum of diseases and biological processes.
                  </span>
                </li>
                <li className="flex gap-x-3">
                 
                  <span>
                    <strong className="font-semibold text-gray-900">Research and Lab Chemicals:</strong> 
                    We provide a wide range of high-quality research and lab chemicals, ensuring precision and reliability for your scientific needs.
                  </span>
                </li>
                <li className="flex gap-x-3">
                 
                  <span>
                    <strong className="font-semibold text-gray-900">Medical Equipment:</strong> Our advanced medical equipment is designed to support healthcare professionals with cutting-edge technology and exceptional performance..
                  </span>
                </li>
                <li className="flex gap-x-3">
                 
                  <span>
                    <strong className="font-semibold text-gray-900">General Order Suppliers</strong> We offer comprehensive general order supplies, catering to various industries with efficiency and reliability..
                  </span>
                </li>
              </ul>
             
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Facing Any Issue? No problem.</h2>
              <strong className="font-semibold text-gray-900">We Are Here 24/7. Feel Free To Contact Us</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}