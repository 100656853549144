/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { request } from "../../api/fetchAPI";
import LocationImg from "../../asset/LocationPic.PNG"
export default function OurPartner() {
    const [partners, setPartners] = useState([])

    const fetchPartner =async ()=>{
        try {
          const data = await request("/webPage/getAll/Partner", "GET")
              setPartners(data)
             
              
        } catch (error) {
          console.log(error)
        }
      }

    useEffect(()=>{
        fetchPartner()
    },[])
    
  return (
    <div className="bg-white">
    <div className='flex flex-col h-52 justify-center items-center mt-5 mb-5  font-semibold text-white bg-indigo-700'>
    <h4 className='text-3xl'>Our Trusted Partners</h4>
    <h6>Building Trust Through Excellence</h6>

    </div>
    <div className='flex justify-center items-center mb-5'>
    <img src={LocationImg} alt="" className='h-[35rem] w-full '/>
    </div>
   
     <div className="mx-auto max-w-7xl mb-5 px-6 lg:px-8">
    
       <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center  lg:grid-cols-3">
         {partners.map((stat) => (
           <div key={stat._id} className="mx-auto h-96 flex max-w-xs flex-col cursor-pointer gap-y-4 hover:bg-slate-300" style={{boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px", padding:"1rem", width:"110%", borderRadius:"15px"}}>
             <img className='rounded-xl h-72' src={stat.partnerImg ? `http://localhost:5000/images/${stat.partnerImg }` : "NULL"} alt="" />
            
             <dd className="order-first text-2xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
             {stat.partnerName}
             </dd>
           </div>
         ))}
       </dl>
     </div>
   </div>
  )
}
