import React from 'react'
import "./Loader.css"

export default function Loader() {
  return (
    <div className='LoaderDiv'>
         <div class="loader"></div>
    </div>
   
  )
}
