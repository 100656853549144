import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Client.css";
import { request } from "../../api/fetchAPI";


export default function Client() {

const [client,setClient] = useState([])

const fetchClient =async ()=>{
  try {

        const data = await request("/webPage/getAll/Client", "GET")
        setClient(data)
       
  } catch (error) {
    
  }
}


  const [slidesToShow, setSlidesToShow] = useState(3);

  useEffect(()=>{
    fetchClient()
  },[])

  useEffect(() => {

    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSlidesToShow(1);
      } else {
        setSlidesToShow(3);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  return (
    <div>
      <div className="w-3/4 m-auto " style={{ backgroundColor: "white" }}>
        <h2 className="flex flex-row justify-center align-middle mt-20 w-30 rounded-xl h-10 text-white bg-indigo-600 font-bold text-3xl " 
        onClick={()=>fetchClient()}>
          Our Clients
        </h2>
        <div className="mt-20 text-blue-900 ">
          <Slider {...settings}>
         
           {client?.map((client)=>(
              <div key={client._id} className="bg-white h-[450px] text-black rounded-xl Clientcard">
              <div className="h-56 rounded-t-xl bg-indigo-500 flex justify-center items-center">
                <img src={client.clientImg ? `https://api.bioscientifictech.com/images/${client.clientImg}`: "NULL"}  alt="" className="h-44 w-44 rounded-full" />
              </div>
              <div className="flex flex-col justify-center items-center gap-4 p-4">
                <p className="text-l font-semibold">{client.clientName}</p>
                <p>{client.Description}</p>
              </div>
            </div>
           ))
            
           }
          </Slider>
        </div>
      </div>
    </div>
  );
}
